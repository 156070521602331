
.horizontal-slider {
  width: 100%;
  height: 8px;
  margin-bottom: 20px;
}

.seats-track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
  /* margin-top: 8px; */
  height: 8px;
}

.seats-track.seats-track-0 {
  background: #4095ce;
}

.seats-track-1 {
  background: #90bfd6;
  margin-left: 10px;
}

.seats-thumb.seats-thumb-0 {
  font-size: 1.2rem;
  font-family: monospace;
  outline: none;
  top: -12px;
  height: 31px;
  width: 31px;
  line-height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #4095ce;
  /* border: 1px solid #07619f; */
  box-shadow: 1px 1px 2px 1px #0000002e;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
}

.seats-thumb:hover {
  /* background-color: #4095ce; */
  /* background-color: #ed7800; */
  /* color: #fff; */
}

.seats-mark {
  /* width: 1px;
  height: 100%;
  background-color: #00000061;
  margin-left: 12px; */
}
/* .seats-mark:nth-of-type(9) {
  background-color: #000000aa;
  height: 200%;
  width: 1px;
  margin-top: -4px;
}
.seats-mark:nth-of-type(15) {
  background-color: #000000aa;
  height: 300%;
  width: 2px;
  margin-top: -7px;
} */

/* add 3 to real value */
.seats-mark:nth-of-type(113),
.seats-mark:nth-of-type(213),
.seats-mark:nth-of-type(313),
.seats-mark:nth-of-type(413),
.seats-mark:nth-of-type(513),
.seats-mark:nth-of-type(613),
.seats-mark:nth-of-type(713),
.seats-mark:nth-of-type(813),
.seats-mark:nth-of-type(913),
.seats-mark:nth-of-type(1003) {
  background-color: #000000aa;
  height: 300%;
  width: 2px;
  margin-top: -4px;
}


/* Themes Overrides */
.CustomPlan-light .seats-track-0,
.CustomPlan-light .seats-track-1 {
  background: #ffbb00;
}
.CustomPlan-light .seats-thumb.seats-thumb-0 {
  background-color: #ffa400;
}

.CustomPlan-dark .seats-track-0,
.CustomPlan-dark .seats-track-1 {
  background: #bc6324;
}
.CustomPlan-dark .seats-thumb.seats-thumb-0 {
  background-color: #b63600;
}


.locations-track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
  /* margin-top: 8px; */
  height: 8px;
}

.locations-track.locations-track-0 {
  background: #4095ce;
}

.locations-track-1 {
  background: #90bfd6;
  margin-left: 10px;
}

.locations-thumb.locations-thumb-0 {
  font-size: 1.2rem;
  font-family: monospace;
  outline: none;
  top: -12px;
  height: 31px;
  width: 31px;
  line-height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #4095ce;
  /* border: 1px solid #07619f; */
  box-shadow: 1px 1px 2px 1px #0000002e;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
}

.locations-thumb:hover {
  /* background-color: #4095ce; */
  /* background-color: #ed7800; */
  /* color: #fff; */
}

.locations-mark {
  width: 1px;
  height: 100%;
  background-color: #00000061;
  margin-left: 12px;
}
/* .locations-mark:nth-of-type(9) {
  background-color: #000000aa;
  height: 200%;
  width: 1px;
  margin-top: -4px;
}
.locations-mark:nth-of-type(15) {
  background-color: #000000aa;
  height: 300%;
  width: 2px;
  margin-top: -7px;
} */

/* add 3 to real value */
.locations-mark:nth-of-type(8),
.locations-mark:nth-of-type(13),
.locations-mark:nth-of-type(18),
.locations-mark:nth-of-type(23),
.locations-mark:nth-of-type(28),
.locations-mark:nth-of-type(33),
.locations-mark:nth-of-type(38) {
  background-color: #000000aa;
  height: 300%;
  width: 2px;
  margin-top: -4px;
}



/* Themes Overrides */
.CustomPlan-light .locations-track-0,
.CustomPlan-light .locations-track-1 {
  background: #ffbb00;
}
.CustomPlan-light .locations-thumb.locations-thumb-0 {
  background-color: #ffa400;
}

.CustomPlan-dark .locations-track-0,
.CustomPlan-dark .locations-track-1 {
  background: #bc6324;
}
.CustomPlan-dark .locations-thumb.locations-thumb-0 {
  background-color: #b63600;
}