
.ConfigsListContent {
  display: flex;
  flex-wrap: wrap;
}
.ConfigsList {
  flex: 1 1 33%;
  /* margin-right: 20px; */
}
.configItem {
  padding: 1vh 1vw;
  cursor: pointer;
  border: 2px solid #1976d200;
  border-radius: 4px;
}
.configItem.selected {
  border: 2px solid #1976d299;
  background-color: #1976d211;
}
.ConfigW {
  flex: 1 1 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ConfigContent {
  flex: 1;
  display: flex;
  overflow: auto;
  max-width: 88vw;
}
/* .actionButton {
  margin-right: 1px;
} */
.configQRCode {
  width: 256px;
  height: 256px;
}
.configFmt {
  resize: none;
  padding: 1vh 1vw;
  /* font-size: 1.4vmin; */
  font-size: 1rem;
  outline: none!important;
  border-radius: 4px;
  /* border: 1px solid #4295cf99; */
}

.ConfigsListButtons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: space-evenly;
  margin-bottom: 1vh;

}
.CreateConfig {
  width: 100%;
}
.CreateConfig .serversList {
  /* max-height: 20vh; */
}

